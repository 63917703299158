<template>
  <div class="abonos-prestamo mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Abono a préstamos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  :items="profesionales"
                  outlined
                  rounded
                  dense
                  item-text="nombre"
                  label="Profesionales"
                  v-model="profesional"
                  return-object
                  @change="loadPrestamos()"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" sm="">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Prestamos pendientes
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :search="search"
                      :items="pendientes"
                      :footer-props="{ itemsPerPageText: 'Prestamos' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              append-icon="mdi-magnify"
                              label="Buscar"
                              class="mt-6"
                              v-model="search"
                            ></v-text-field>
                          </v-col>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        {{ item.valor | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.view="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              color="primary"
                              @click="
                                $router.push('/detalleAbonoPrestamo/' + item.id)
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver prestamo</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Prestamos pagados
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="pagos"
                      :footer-props="{ itemsPerPageText: 'Prestamos' }"
                    >
                      <template v-slot:item.valor="{ item }">
                        {{ item.valor | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.view="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              color="primary"
                              @click="
                                $router.push('/detalleAbonoPrestamo/' + item.id)
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver prestamo</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "abonoPrestamo",
  data: () => ({
    search: "",
    profesional: {},
    profesionales: [],
    headers: [
      {
        text: "Fecha",
        value: "fecha",
      },
      {
        text: "Gasto",
        value: "gasto",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "Abonos",
        value: "abonos",
      },
      {
        text: "Saldo",
        value: "saldo",
      },
      {
        text: "",
        value: "view",
      },
    ],
    prestamos: [],
  }),
  methods: {
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    loadPrestamos() {
      const body = {
        route: "/prestamos_desc",
        params: {
          id: this.profesional.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.prestamos = response.data.data;
        }
      });
    },
  },
  created() {
    this.loadProfesionales();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_prestamos() {
      return this.prestamos.map((p) => {
        return {
          id: p.id,
          fecha: moment(p.fecha).format("ll"),
          gasto: p.gasto,
          valor: parseFloat(p.valor),
          abonos: parseFloat(p.abonos),
          saldo: parseFloat(p.valor) - parseFloat(p.abonos),
        };
      });
    },
    pendientes() {
      return this.lista_prestamos.filter((p) => {
        return p.saldo > 0;
      });
    },
    pagos() {
      return this.lista_prestamos.filter((p) => {
        return p.saldo === 0;
      });
    },
  },
};
</script>
